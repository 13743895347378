import AppPropTypes from '../../shared/appPropTypes'
import CssBaseline from '@material-ui/core/CssBaseline'
import Fade from '@material-ui/core/Fade'
import MikeLogin from '../../components/mike-login/MikeLogin'
import IAMNav from '../../components/IAMNav'
import MikeSupportMenuContainer from '@mike/mike-shared-frontend/mike-support'
import { ReactComponent as DataAdmin } from '@mike/mike-shared-frontend/media/icons/DataAdmin'
import MikeTopbar from '@mike/mike-shared-frontend/mike-topbar/mike-topbar'
import ProgressSpinner from '@mike/mike-shared-frontend/mike-topbar-progress-spinner'
import MikeNavWarnBanner from '@mike/mike-shared-frontend/mike-nav-warn-banner/MikeNavWarnBanner'
import MikeSlidingPanel from '@mike/mike-shared-frontend/mike-sliding-panel'
import PropTypes from 'prop-types'
import React, { CSSProperties, Fragment, useState } from 'react'
import { useIntl } from 'react-intl'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import CreateTenantForm from '../../components/CreateTenantForm'

import Grid from '@material-ui/core/Grid'
import ChangeSiteButton from '../buttons/ChangeSiteButton'
import MikeCloudApps from '../MikeCloudApps'
import CreditsInfo from '../Credits/CreditsToolbar/CreditsInfo'
import Menu from '@material-ui/core/Menu'
import { SiteOwnersDialogButton } from './SiteOwnersDialogButton'
import { TenantButtons } from './TenantButtons'
import { useTypedSelector } from '../../reducers'

const styles = theme => ({
    root: {
        display: 'flex'
    },
    appBarSpacer: {
        minHeight: theme.spacing(5),
        backgroundColor: theme.palette.lightGrey.main
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        paddingTop: theme.spacing(3),
        backgroundColor: theme.palette.lightGrey.main
    },
    header: {
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        borderBottom: '2px solid ' + theme.palette.mediumGrey.main,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    },
    tenantContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '33vw'
    },
    tenantSelect: {
        width: '15vw'
    },
    tenantButton: {
        backgroundColor: theme.palette.lightGrey.main,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    placeHolder: {
        backgroundColor: theme.palette.lightGrey.main,
        minHeight: theme.spacing(1.5)
    },
    placeSelectTop: {
        backgroundColor: theme.palette.lightGrey.main,
        minHeight: theme.spacing(0.5)
    },
    placeSelectBottom: {
        backgroundColor: theme.palette.lightGrey.main,
        minHeight: theme.spacing(1)
    },
    headerRightPlaceHolder: {
        minWidth: '33vw'
    },
    iamNav: {
        width: '33vw',
        backgroundColor: theme.palette.lightGrey.main
    },
    actions: {
        display: 'flex',
        '& div': {
            '& svg': {
                fill: 'grey'
            }
        }
    },
    creditsItem: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        color: theme.palette.primary.main,
        cursor: 'pointer'
    },
    creditsText: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: theme.palette.secondary.main
    },
    rightActionButtons: {
        display: 'flex',
        justifyContent: 'right',
        marginRight: '32px'
    },
    switchTenantButton: {
        marginRight: 20
    }
})

const APPLICATION_TYPE = 'Application'

const APP_TO_FILTER = [
    'Company Subscription (giving access to Cloud Admin)',
    'Flood Screener',
    'Global Seas'
]

const AppLayout = ({
    children,
    classes,
    support,
    tutorial,
    progressItems,
    tenants,
    openCreateTenant,
    onCreateTenantCloseClick,
    onCreateTenant,
    onOpenCreateTenant,
    isAdmin,
    loadingApps,
    features,
    currentProject,
    user
}) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)

    const isMenuOpen = Boolean(menuAnchorEl!)

    const handleOpenMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenuAnchorEl(null)
    }

    const intl = useIntl()

    const apps = features.filter(feat => {
        return feat.type === APPLICATION_TYPE && !APP_TO_FILTER.includes(feat.name)
    })

    const tenantName = useTypedSelector((state) => {
        const foundTenant = state.tenant.tenants.find((tenant) => tenant.id === state.auth.user?.tenantId);
        return foundTenant?.name || ''
    })

    const mailConfig = {
        to: support.toEmail,
        subject: support.subject,
        body: support.body
    }
    const documentationConfig = { href: tutorial.link, target: '_blank' }
    const documentationLibraryConfig = {
        href: tutorial.linkLibrary,
        target: '_blank',
        label: 'Documentation Library'
    }
    const platformDocumentationConfig = {
        href: tutorial.platformDocumentation,
        target: '_blank',
        label: 'Platform Documentation'
    }

    const portalSupportConfig = {
        href: support.portalLink,
        target: '_blank',
        label: 'Support portal'
    }

    const logoutCallback = React.useCallback(() => {
        const _paq = (window['_paq'] = window['_paq'] || [])
        _paq.push(['resetUserId'])
        _paq.push(['appendToTrackingUrl', 'new_visit=1'])
        _paq.push(['trackPageView'])
        _paq.push(['appendToTrackingUrl', ''])
    }, [])

    const loginProps = () => ({
        logInLabel: 'Log in',
        logOutLabel: 'Log out',
        logoutClickCallback: logoutCallback
    })

    const topBarProps = {
        appIcon: <DataAdmin />,
        appTitle: 'MIKE Cloud Admin',
        loginButton: <MikeLogin {...loginProps()} />,
        supportButton: (
            <MikeSupportMenuContainer
                mailConfig={mailConfig}
                documentationConfig={[
                    documentationConfig,
                    documentationLibraryConfig,
                    platformDocumentationConfig,
                    portalSupportConfig
                ]}
                tooltipPosition={{ top: -60, left: -20 }}
            />
        ),
        actionsArea: (
            <div className={classes.actions}>
                {progressItems && <ProgressSpinner progressItems={progressItems} />}
            </div>
        ),
        homeButton: null
    }

    const gridStyles: CSSProperties = {
        paddingBottom: 20,
        paddingTop: 20
    }

    const pageHeaderStyle: CSSProperties = {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginLeft: '40px'
    }

    const menuSpanStyle: CSSProperties = {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: '#46b1e1'
    }

    const menuStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '1rem'
    }

    return (
        <>
            <CssBaseline />

            <Fade in>
                <div className={classes.root}>
                    <MikeTopbar {...topBarProps} />

                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <MikeNavWarnBanner showAlways={false} />

                        <Grid container style={gridStyles}>
                            <Grid item xs style={pageHeaderStyle}>
                                <Typography variant="h6">{tenantName}</Typography>

                                <span
                                    aria-haspopup="true"
                                    aria-expanded={isMenuOpen ? 'true' : undefined}
                                    style={menuSpanStyle}
                                    onClick={handleOpenMenuClick}
                                >
                                    Manage Sites
                </span>

                                <Menu
                                    keepMounted
                                    anchorEl={menuAnchorEl}
                                    open={isMenuOpen}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    onClose={handleMenuClose}
                                    getContentAnchorEl={null}
                                >
                                    <div style={menuStyle}>
                                        {tenants?.length > 1 && (
                                            <ChangeSiteButton
                                                style={{
                                                    color: '#fff'
                                                }}
                                            />
                                        )}

                                        <TenantButtons
                                            tenants={tenants}
                                            user={user}
                                            onOpenCreateTenant={onOpenCreateTenant}
                                        />

                                        <SiteOwnersDialogButton
                                            isMenuOpen={isMenuOpen}
                                            classes={classes}
                                        />
                                    </div>
                                </Menu>
                            </Grid>
                            <MikeSlidingPanel
                                position="right"
                                isOpen={openCreateTenant}
                                onClose={onCreateTenantCloseClick}
                                titleArea={intl.formatMessage({
                                    id: 'createTenantForm.createTenantButton'
                                })}
                                contentArea={
                                    <CreateTenantForm handleCreateTenant={onCreateTenant} />
                                }
                                actionsArea={null}
                                noGrayOverlay={false}
                            />
                            {user && user.tenantId ? (
                                <Grid
                                    item
                                    xs
                                    direction="column"
                                    justifyContent="center"
                                    container
                                >
                                    <IAMNav showSiteAccess={isAdmin} />
                                </Grid>
                            ) : null}

                            <Grid
                                item
                                xs
                                direction="row"
                                justifyContent="flex-end"
                                alignContent="center"
                                container
                            >
                                <div className={classes.rightActionButtons}>
                                    <CreditsInfo />
                                </div>
                            </Grid>
                        </Grid>
                        <MikeCloudApps
                            loading={loadingApps}
                            applications={apps}
                            projectId={currentProject?.details?.data?.id}
                            tenantId={user?.tenantId}
                        />
                        {children}
                    </main>
                </div>
            </Fade>
        </>
    )
}

AppLayout.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    loginConfig: AppPropTypes.loginConfig.isRequired,
    support: AppPropTypes.support.isRequired,
    tutorial: AppPropTypes.tutorial.isRequired,
    user: AppPropTypes.user,
    appNotifications: PropTypes.array.isRequired,
    progressItems: PropTypes.array,
    loadingTenants: PropTypes.bool.isRequired,
    tenants: PropTypes.array,
    tenantId: PropTypes.string,
    openCreateTenant: PropTypes.bool,
    onCreateTenantCloseClick: PropTypes.func.isRequired,
    onCreateTenant: PropTypes.func.isRequired,
    onOpenCreateTenant: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool,
    isBackOffice: PropTypes.bool,
    loadingApps: PropTypes.bool,
    apps: PropTypes.object,
    project: PropTypes.object,
    features: PropTypes.array,
    currentProject: PropTypes.object
}

const enhance = compose(withStyles(styles))

export default enhance(AppLayout)
