/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react'
import { Field } from 'formik'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import { ReactComponent as Delete } from '@mike/mike-shared-frontend/media/icons/Delete'
import { ReactComponent as Plus } from '@mike/mike-shared-frontend/media/icons/Plus'
import { ReactComponent as Edit } from '@mike/mike-shared-frontend/media/icons/Edit'
import TextField from '../formik/TextField'
import { useIntl } from 'react-intl'
import MikeButton from '@mike/mike-shared-frontend/mike-button'
import { iconStyle } from '../buttons/iconStyles'
import { useMetadataStyles } from './useMetadataStyles'

export interface IMetaData {
    key: string
    value: string
}

interface IProps {
    name: string
    values: Array<IMetaData>
    onChangeMetadata: (metaDatas: Array<IMetaData>) => void
    editing: boolean
    setEditing: () => void
}

const EditMetadata = (props: IProps) => {
    const { name, values, onChangeMetadata, editing, setEditing } = props

    const classes = useMetadataStyles()

    const intl = useIntl()

    const [vals, setVals] = useState(values)

    const removeMetaDataLocal = (md: IMetaData, metaDatas: Array<IMetaData>) => {
        const newVals = metaDatas.filter(metadata => metadata.key !== md.key)
        setVals(newVals)
        onChangeMetadata(newVals)
    }

    const addMetaDataLocal = (metaDatas: Array<IMetaData>) => {
        const newVals = [...metaDatas, { key: '', value: '' }]
        setVals(newVals)
        onChangeMetadata(newVals)
    }

    return editing ? (
        <Field name={name}>
            {({
                form: { values } // also touched, errors, setXXXX, handleXXXX, dirty, isValid, status, etc.
            }) => (
                    <Grid item xs={12}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={0}
                        >
                            <Grid item xs={5}>
                                <Typography variant={'body2'}>
                                    <b>
                                        {intl.formatMessage({
                                            id: 'editDatasetForm.metadata.editing'
                                        })}
                                    </b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <MikeButton
                                    buttontype="text"
                                    onClick={() => addMetaDataLocal(values.metadata)}
                                >
                                    {intl.formatMessage({ id: 'editDatasetForm.metadata.create' })}
                                </MikeButton>
                            </Grid>
                            <Grid item xs={1}>
                                <MikeButton
                                    buttontype="text"
                                    style={{ minWidth: 40, padding: 0 }}
                                    onClick={() => addMetaDataLocal(values.metadata)}
                                >
                                    <Plus className={iconStyle} />
                                </MikeButton>
                            </Grid>
                        </Grid>
                        {vals.map((val: IMetaData, index: number) => (
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                key={index}
                                spacing={0}
                            >
                                <Grid item xs={5}>
                                    <TextField
                                        name={`${name}.${index}.key`}
                                        label={intl.formatMessage({
                                            id: 'editDatasetForm.metadata.key'
                                        })}
                                        fullWidth
                                        required
                                        addPlaceHolder={true}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        name={`${name}.${index}.value`}
                                        label={intl.formatMessage({
                                            id: 'editDatasetForm.metadata.value'
                                        })}
                                        fullWidth
                                        required
                                        addPlaceHolder={true}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button
                                        style={{ minWidth: 40, padding: 0 }}
                                        onClick={() => removeMetaDataLocal(val, values.metadata)}
                                    >
                                        <Delete />
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                )}
        </Field>
    ) : (
            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                >
                    <Grid item xs={5}>
                        <Typography variant={'body2'}>
                            <b>
                                {intl.formatMessage({ id: 'editDatasetForm.metadata.current' })}
                            </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <MikeButton buttontype="text" onClick={() => setEditing()}>
                            {intl.formatMessage({ id: 'editDatasetForm.metadata.setEditing' })}
                            <Edit className={iconStyle} />
                        </MikeButton>
                    </Grid>
                </Grid>
                {vals.map((val: IMetaData, index) => (
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        key={index}
                        spacing={0}
                    >
                        <Grid item xs={6} className={classes.bottomBorder}>
                            <Tooltip title={val.key} classes={{ tooltip: classes.tooltip }}>
                                <Typography className={classes.longText}>{val.key}</Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={6} className={classes.bottomBorder}>
                            <Tooltip title={val.value} classes={{ tooltip: classes.tooltip }}>
                                <Typography className={classes.longText}>{val.value}</Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        )
}

export default EditMetadata
