import React, { useMemo } from 'react'
import { Typography, Breadcrumbs, Tooltip } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import IProject from '../../model/IProject'
import ProjectLink from '../ProjectLink'
import { useTypedSelector } from '../../reducers'

const useStyles = makeStyles(theme =>
    createStyles({
        mikeBreadcrumbsLinkLabel: {
            cursor: 'pointer',
            color: theme.palette.text.primary,
            '&:hover': {
                color: theme.palette.text.primary
            },
            '&:active': {
                color: theme.palette.text.primary
            },
            '&:focus': {
                color: theme.palette.text.primary
            },
            maxWidth: '15vw',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block'
        },
        mikeBreadcrumbsLastLabel: {
            fontWeight: 'bold',
            color: theme.palette.text.primary,
            maxWidth: '15vw',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            margin: '15px'
        }
    })
)

const AppBreadcrumbNav = () => {
    const projectPath = useTypedSelector<IProject[] | null>(
        state => state['project'].path.data
    )

    const tenantName = useTypedSelector((state) => {
        const foundTenant = state.tenant.tenants.find((tenant) => tenant.id === state.auth.user?.tenantId);
        return foundTenant?.name || ''
    })

    const tenantId =
        useTypedSelector(state => state['auth'].user?.tenantId) || null

    const effectiveRole = useTypedSelector(state => {
        const data = state['customerUsers']?.data
        const userId = state['auth']?.user?.id

        if (data && userId && !state.customerUsers.loading) return data[userId]?.role || ''

        return ''
    })

    const usersAmount = useTypedSelector(state => {
        return state.users.users.length
    })

    const classes = useStyles()

    const path: IProject[] | null = useMemo(() => {
        if (projectPath && projectPath[0]?.id !== tenantId) {
            return [
                ({
                    id: tenantId,
                    name: tenantName,
                    parentProjectId: null,
                    effectiveUserRole: usersAmount ? effectiveRole : ''
                } as unknown) as IProject,
                ...projectPath
            ]
        }

        return projectPath
    }, [projectPath, tenantId, tenantName, effectiveRole, usersAmount])

    return (
        <Breadcrumbs maxItems={4} separator={<NavigateNextIcon fontSize="small" />}>
            {path?.map((item: IProject, index, array) => {
                const { name, id, parentProjectId, effectiveUserRole } = item
                const last = index === array.length - 1
                const shownName = index === 0 ? tenantName : name;
                const title = effectiveUserRole
                    ? `${shownName} [${effectiveUserRole}]`
                    : shownName

                return (
                    <Tooltip key={index + '_' + name} title={title}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '.5rem'
                            }}
                        >
                            <Typography
                                variant="body2"
                                className={
                                    last
                                        ? classes.mikeBreadcrumbsLastLabel
                                        : classes.mikeBreadcrumbsLinkLabel
                                }
                            >
                                {last ? (
                                    name
                                ) : (
                                        <ProjectLink
                                            text={shownName}
                                            to={parentProjectId && id ? id : `/site`}
                                        />
                                    )}
                            </Typography>
                            {effectiveUserRole ? (
                                <span style={{ fontSize: '14px' }}>
                                    [
                                    <span style={{ fontStyle: 'italic' }}>
                                        {effectiveUserRole}
                                    </span>
                  ]
                                </span>
                            ) : null}
                        </div>
                    </Tooltip>
                )
            })}
        </Breadcrumbs>
    )
}

export default AppBreadcrumbNav
