import React, { useCallback, useEffect, useState, useRef } from 'react'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ReactComponent as DataSet } from '@mike/mike-shared-frontend/media/icons/DataSet'
import { ReactComponent as Folder } from '@mike/mike-shared-frontend/media/icons/Folder'
import { css } from 'emotion'
import capitalize from 'lodash/capitalize'
import AppNav from '../AppNav'
import { useTypedSelector } from '../../reducers'
import SearchInput from '../SearchInput'
import { useIntl } from 'react-intl'
import RefreshButton from '../buttons/RefreshButton'
import CreateSubprojectButton from '../buttons/CreateSubprojectButton'
import { StateEnum } from '../../model/StateEnum'
import { useDispatch } from 'react-redux'
import { loadRootProjectList } from '../../actions/rootProjectActions'
import DataTable from '../DataTable'
import messages from '../../shared/messages'
import LongText from '../DataTable/renders/LongText'
import LongName from '../DataTable/renders/LongName'
import DateTime from '../DataTable/renders/DateTime'
import DataTableRenders, { actionStyle, iconStyle } from '../DataTable/renders'
import { MikeSlidingPanel } from '@mike/mike-shared-frontend'
import CreateProjectForm from '../CreateProjectForm'
import { loadCustomerUsers } from '../../actions/customerUsers'
import { loadProjectMembers } from '../../actions/project'
import { createProject, deleteProjects } from '../../actions/projects'
import { ReactComponent as Delete } from '@mike/mike-shared-frontend/media/icons/Delete'
import IProject from '../../model/IProject'
import ConfirmationDialog from '../Dialog/ConfirmationDialog'
import DeleteButton from '../buttons/DeleteButton'
import { getCapabilities } from '../../apis/project'
import { resetRootProjectLoadingState } from '../../reducers/rootProjectList'

const useStyles = makeStyles(theme =>
    createStyles({
        projectDetails: {
            display: 'flex',
            paddingLeft: '40px',
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(3),
            position: 'sticky',
            zIndex: 1
        },
        tableTopHeader: {
            position: 'sticky',
            top: theme.spacing(3),
            paddingRight: theme.spacing(4),
            zIndex: 1,
            backgroundColor: '#f2f5f7'
        },
        mikeBreadcrumpsLastLabel: {
            fontWeight: 'bold',
            color: theme.palette.text.primary,
            maxWidth: '15vw',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            margin: '15px'
        },
        tableTopActionButtons: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            paddingBottom: theme.spacing(3),
            marginLeft: theme.spacing(-1),
            paddingLeft: theme.spacing(5)
        },
        leftActionButtons: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end'
        },
        rightActionButtons: {
            display: 'flex',
            justifyContent: 'center'
        },
        createSubprojectButton: {
            marginLeft: 20
        }
    })
)

// TODO: these renderes and the column definitions are the same as in
//       ProjectContentTable.js and should move to a shared place.
//       I sadly was in a hurry due to the deadline and did not have time to
//       refactor this. Bodo
const formatRender = value => {
    return value ? capitalize(value) : 'Folder'
}

const longTextRender = value => <LongText longText={value} />

const itemNameRender = (value, item) => (
    <LongName longName={value} icon={'datasetType' in item ? DataSet : Folder} />
)

const dateTimeRender = value => <DateTime jsonTime={value} />

const hoverStyle = item => {
    return 'datasetType' in item
        ? null
        : css`
        &:hover {
          cursor: pointer;
        }
      `
}

const RootProject = () => {
    const intl = useIntl()
    const classes = useStyles()
    const dispatch = useDispatch()

    const {
        state: rootProjectState,
        params: queryParams,
        projects,
        totalCount
    } = useTypedSelector(state => state['rootProjectList'])

    const tenantId =
        useTypedSelector(state => state['auth']?.user?.tenantId) || ''
    const user = useTypedSelector(state => state['auth'].user)
    const loadingCustomerUsers = useTypedSelector(
        state => state['customerUsers'].loading
    )
    const loadingProjectMembers = useTypedSelector(
        state => state['project'].members.loading
    )

    const tenantName = useTypedSelector((state) => {
        const foundTenant = state.tenant.tenants.find((tenant) => tenant.id === state.auth.user?.tenantId);
        return foundTenant?.name || ''
    })

    const effectiveRole = useTypedSelector(state => {
        const data = state['customerUsers']?.data
        const userId = state['auth']?.user?.id

        if (data && userId && !state.customerUsers.loading) return data[userId]?.role || ''

        return ''
    })

    const [selectedItems, setSelectedItems] = useState<IProject[]>([])
    const [createProjectOpen, setCreateProjectOpen] = useState(false)
    const [deleteItems, setDeleteItems] = useState<IProject[]>([])
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [capabilities, setCapabilities] = useState({})

    const [searchText, setSearchText] = useState('')
    const canCreateContent =
        useTypedSelector(state => state['admin']?.isRootContributor) || false

    const isAdmin = useTypedSelector(state => state['admin']?.isAdmin) || false

    useEffect(() => {
        dispatch(loadCustomerUsers(tenantId))

        if (isAdmin) {
            dispatch(loadProjectMembers(tenantId))
        }
    }, [dispatch, isAdmin, tenantId])

    useEffect(() => {
        return () => {
            // reset the state when leaving the page so
            // that the next time we enter the page, we
            // start with reloading the data
            dispatch(resetRootProjectLoadingState())
        }
    }, [dispatch])

    useEffect(() => {
        if (rootProjectState === StateEnum.INIT) {
            dispatch(
                loadRootProjectList({ ...queryParams, page: 0, namePrefix: searchText })
            )
        }
    }, [rootProjectState, dispatch, queryParams, searchText])

    const loading =
        rootProjectState === StateEnum.LOADING ||
        rootProjectState === StateEnum.INIT

    const onSearchTextChange = text => {
        setSearchText(text)
        dispatch(loadRootProjectList({ ...queryParams, namePrefix: text }))
    }

    const onRefreshClick = () => {
        dispatch(loadRootProjectList(queryParams))
    }

    const onCreateProjectOpenClick = () => {
        setCreateProjectOpen(true)
    }

    const history = useHistory()
    const onColumnClick = useCallback(
        item => {
            history.push(`/project/${item.id}`)
        },
        [history]
    )

    const onHandleRequestSort = (orderBy, sortOrder) => {
        dispatch(
            loadRootProjectList({
                ...queryParams,
                page: 0,
                orderBy,
                sortOrder
            })
        )
    }

    const onChangePage = page => {
        dispatch(
            loadRootProjectList({
                ...queryParams,
                page
            })
        )
    }

    const onChangeRowsPerPage = rowsPerPage => {
        dispatch(
            loadRootProjectList({
                ...queryParams,
                rowsPerPage
            })
        )
    }

    const onCreateProjectCloseClick = () => {
        setCreateProjectOpen(false)
    }
    const onCreateProject = data => {
        // Member should not be part of this list
        data.members = data.members.filter(member => {
            return member.userId !== user?.id
        })
        dispatch(
            createProject(data, () => {
                dispatch(loadRootProjectList(queryParams))
            })
        )
        setCreateProjectOpen(false)
    }

    const canDelete = id => {
        return capabilities[id]?.canEdit || false
    }

    const renderDeleteItem = row => {
        const canDel = canDelete(row.id)
        return (
            <div className={actionStyle(!canDel)}>
                <Delete className={iconStyle(!canDel)} />
        Delete
            </div>
        )
    }

    const deleteActionClicked = (row: IProject) => {
        if (canDelete(row.id)) {
            setDeleteItems([row])
            setShowDeleteConfirmation(true)
        }
    }

    const onOkDeleteProjects = () => {
        setShowDeleteConfirmation(false)
        const ids = deleteItems.map(item => item.id).filter(id => canDelete(id))

        dispatch(
            deleteProjects(ids, () => {
                dispatch(loadRootProjectList(queryParams))
                setSelectedItems(selectedItems.filter(item => !ids.includes(item.id)))
            })
        )
    }

    const onCancelDeleteProjects = () => {
        setShowDeleteConfirmation(false)
    }

    const onDeleteItems = () => {
        setDeleteItems(selectedItems)
        setShowDeleteConfirmation(true)
    }

    const fetchCapabilitiesIfNeeded = id => {
        if (capabilities[id] === null || capabilities[id] === undefined) {
            getCapabilities(id).then(newCap => {
                setCapabilities({ ...capabilities, [id]: newCap })
            })
        }
    }

    const actionPopupStateChange = (open, row) => {
        if (open) {
            fetchCapabilitiesIfNeeded(row.id)
        }
    }

    const onSelectionChange = items => {
        items.forEach(item => {
            fetchCapabilitiesIfNeeded(item.id)
        })
        setSelectedItems(items)
    }

    return (
        <>
            <ConfirmationDialog
                open={showDeleteConfirmation}
                title={intl.formatMessage({
                    id: 'screens.projects.confirmationDialog.title'
                })}
                message={intl.formatMessage(
                    deleteItems.length > 1
                        ? { id: 'screens.projects.confirmationDialog.message.multipleProj' }
                        : { id: 'screens.projects.confirmationDialog.message.singleProj' }
                )}
                ok={intl.formatMessage(messages.yesDelete)}
                onOk={onOkDeleteProjects}
                onCancel={onCancelDeleteProjects}
            />
            <AppNav tenantId={tenantId} />
            <div className={classes.tableTopHeader}>
                <div className={classes.projectDetails}>
                    <Typography
                        variant="body2"
                        className={classes.mikeBreadcrumpsLastLabel}
                    >
                        {tenantName}{' '}
                        {effectiveRole ? (
                            <span>
                                [<span style={{ fontStyle: 'italic' }}>{effectiveRole}</span>]
                            </span>
                        ) : null}
                    </Typography>
                </div>

                <div className={classes.tableTopActionButtons}>
                    <div className={classes.leftActionButtons}>
                        <SearchInput
                            id="project-details-search-input"
                            placeholder={intl.formatMessage({
                                id: 'projectDetails.input.search.placeholder'
                            })}
                            autoFocus
                            text={searchText}
                            onTextChange={onSearchTextChange}
                        />
                        <RefreshButton onClick={onRefreshClick} />

                        {selectedItems.length > 0 && (
                            <DeleteButton disabled={!canDelete} onClick={onDeleteItems} />
                        )}
                    </div>
                    <div className={classes.rightActionButtons}>
                        <div className={classes.createSubprojectButton}>
                            {canCreateContent && (
                                <CreateSubprojectButton onClick={onCreateProjectOpenClick} />
                            )}
                        </div>
                    </div>
                </div>

                <DataTable
                    actionsPopupStateChange={actionPopupStateChange}
                    actions={[
                        {
                            name: 'Delete',
                            callBack: deleteActionClicked,
                            render: renderDeleteItem,
                            disableGutters: true
                        }
                    ]}
                    loading={loading}
                    columns={[
                        {
                            field: 'name',
                            label: intl.formatMessage(messages.name),
                            render: itemNameRender,
                            className: hoverStyle
                        },
                        {
                            field: 'description',
                            label: intl.formatMessage(messages.description),
                            render: longTextRender,
                            className: hoverStyle
                        },
                        {
                            field: 'datasetType',
                            label: intl.formatMessage(messages.type),
                            render: formatRender,
                            className: hoverStyle
                        },
                        {
                            field: 'datasetFormat',
                            label: intl.formatMessage(messages.format),
                            render: formatRender,
                            className: hoverStyle
                        },
                        {
                            field: 'storageSize',
                            label: intl.formatMessage(messages.size),
                            render: DataTableRenders.size(1024),
                            className: hoverStyle
                        },
                        {
                            field: 'createdAt',
                            label: intl.formatMessage(messages.createdAt),
                            render: dateTimeRender,
                            className: hoverStyle
                        },
                        {
                            field: 'updatedAt',
                            label: intl.formatMessage(messages.editedAt),
                            render: dateTimeRender,
                            className: hoverStyle
                        }
                    ]}
                    idField="id"
                    data={projects}
                    selectedRows={selectedItems}
                    onColumnClick={onColumnClick}
                    onSelectionChange={onSelectionChange}
                    onHandleRequestSort={onHandleRequestSort}
                    page={queryParams.page}
                    _rowsPerPage={queryParams.rowsPerPage}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    totalCount={totalCount}
                    _order={[queryParams.sortOrder]}
                    _orderBy={[queryParams.orderBy]}
                />

                <MikeSlidingPanel
                    position="right"
                    isOpen={createProjectOpen}
                    onClose={onCreateProjectCloseClick}
                    titleArea={intl.formatMessage({
                        id: 'createProjectForm.heading.title'
                    })}
                    contentArea={
                        <CreateProjectForm
                            user={user}
                            editing={false}
                            initialValues={null}
                            isFolder
                            loadingCustomerUsers={loadingCustomerUsers}
                            loadingProjectMembers={loadingProjectMembers}
                            onSubmit={onCreateProject}
                        />
                    }
                    actionsArea={null}
                    noGrayOverlay={false}
                />
            </div>
        </>
    )
}

export default RootProject
