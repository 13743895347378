import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, defineMessages } from 'react-intl'
import { compose } from 'recompose'
import { Formik, Form } from 'formik'
import merge from 'lodash/merge'
import objectHash from 'object-hash'
import { useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import MikeButton from '@mike/mike-shared-frontend/mike-button'
import Autocomplete from '@material-ui/lab/Autocomplete'

import AppPropTypes from '../../shared/appPropTypes'
import messages from '../../shared/messages'
import { ROLE_TYPES, ACCESS_LEVELS } from '../../helpers/project'

import TextField from '../formik/TextField'
import ProjectMembersField from '../formik/ProjectMembersField'
import { TextField as MuiTextField } from '@material-ui/core'
import { loadProjectMembers } from '../../actions/project'
import { useTypedSelector } from '../../reducers'
import { loadCustomerUsers } from '../../actions/customerUsers'

const defaultInitialValues = {
  name: '',
  description: '',
  accessLevel: ACCESS_LEVELS.SHARED,
  members: []
}

const validateForm = values => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Required'
  }

  return errors
}

const localMessages = defineMessages({
  membersPlaceholder: {
    id: 'createProjectForm.members.placeholder'
  },
  createProjectButton: {
    id: 'createProjectForm.createProjectButton'
  },
  editProjectButton: {
    id: 'createProjectForm.editProjectButton'
  },
  createSubprojectButton: {
    id: 'createProjectForm.createSubprojectButton'
  },
  editSubprojectButton: {
    id: 'createProjectForm.editSubprojectButton'
  }
})

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  submitButton: {
    float: 'right'
  }
})

const CreateProjectForm = props => {
  const {
    classes,
    editing = false,
    intl,
    initialValues,
    loadingCustomerUsers = false,
    loadingProjectMembers = false,
    onSubmit,
    isSubProject = false,
    isFolder = false,
    user
  } = props

  const customerUsers = useTypedSelector(state => state['customerUsers'].data)
  const tenantId = useTypedSelector(state => state['auth'].user?.tenantId)

  const mergedInitialValues = editing
    ? {
        ...merge({}, defaultInitialValues, initialValues),
        members: initialValues.members ?? []
      }
    : {
        ...defaultInitialValues,
        members: []
      }

  const [customerUsersArray, setCustomerUsersArray] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    if (!customerUsers || Object.keys(customerUsers).length === 0) {
      dispatch(loadCustomerUsers(tenantId))
    }
  }, [])

  useEffect(() => {
    if (initialValues?.id) {
      dispatch(loadProjectMembers(initialValues.id))
    }
  }, [dispatch, initialValues?.id])

  useEffect(() => {
    const loggedInUserId = user.id
    const users = Object.values(customerUsers)
    // NOTE: on creation the logged-in user cannot be added as a member because the API will fail
    editing
      ? setCustomerUsersArray(users)
      : setCustomerUsersArray(users.filter(u => u.userId !== loggedInUserId))
  }, [customerUsers, editing, user])

  const { canEdit = true, canGrantAccess = true } = editing
    ? initialValues.capabilities
    : {}

  return (
    <Formik
      key={objectHash(mergedInitialValues)}
      initialValues={mergedInitialValues}
      validate={validateForm}
      onSubmit={onSubmit}
    >
      {formik => {
        const {
          handleSubmit,
          setFieldValue,
          handleChange,
          setFieldTouched,
          values,
          errors
        } = formik

        const change = (name, e) => {
          e.persist()
          handleChange(e)
          setFieldTouched(name, true, false)
        }

        const onSelectedCustomerChange = (_event, newCustomer) => {
          if (newCustomer) {
            let role = newCustomer.role
            if (!role) {
              role =
                newCustomer.id === user.id
                  ? ROLE_TYPES.OWNER
                  : ROLE_TYPES.READER
            }
            const item = values?.members?.find(
              el => el.userId === newCustomer.userId
            )

            const member = { ...newCustomer, userId: newCustomer.id }

            if (!item) {
              setFieldValue('members', [
                ...values.members,
                { ...member, role: role }
              ])
            }
          }
        }

        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label={intl.formatMessage(messages.name)}
                  disabled={!canEdit}
                  fullWidth
                  required
                  autoFocus
                  onChange={change.bind(null, 'name')}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="description"
                  label={intl.formatMessage(messages.description)}
                  disabled={!canEdit}
                  fullWidth
                  type="textarea"
                  rows={4}
                  onChange={change.bind(null, 'description')}
                />
              </Grid>

              <Grid item xs={12}>
                {canGrantAccess && (
                  <Autocomplete
                    id="members-select"
                    clearOnBlur
                    disablePortal={false}
                    loading={loadingCustomerUsers}
                    options={customerUsersArray}
                    getOptionLabel={option => option.name}
                    onChange={onSelectedCustomerChange}
                    renderInput={params => (
                      <MuiTextField
                        {...params}
                        error={Boolean(errors.members)}
                        helperText={errors.members && errors.members}
                        variant="filled"
                        label={intl.formatMessage(messages.members)}
                        placeholder={intl.formatMessage(
                          localMessages.membersPlaceholder
                        )}
                      />
                    )}
                    renderOption={params => params.name}
                  />
                )}

                <ProjectMembersField
                  name="members"
                  disabled={!canGrantAccess}
                  loading={loadingProjectMembers}
                />
              </Grid>
              <Grid item xs={12}>
                <MikeButton
                  className={classes.submitButton}
                  color="secondary"
                  variant="contained"
                  onClick={handleSubmit}
                  type="button"
                >
                  {editing
                    ? isSubProject
                      ? intl.formatMessage(localMessages.editSubprojectButton)
                      : intl.formatMessage(localMessages.editProjectButton)
                    : isSubProject
                    ? intl.formatMessage(localMessages.createSubprojectButton)
                    : intl.formatMessage(
                        isFolder
                          ? localMessages.createSubprojectButton
                          : localMessages.createProjectButton
                      )}
                </MikeButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
CreateProjectForm.propTypes = {
  classes: PropTypes.object.isRequired,
  editing: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  loadingCustomerUsers: PropTypes.bool,
  loadingProjectMembers: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  isSubProject: PropTypes.bool,
  isFolder: PropTypes.bool,
  user: AppPropTypes.user.isRequired
}

const enhance = compose(injectIntl, withStyles(styles))

export default enhance(CreateProjectForm)
