import { call, put, takeEvery, fork } from 'redux-saga/effects'
import { addError } from '../actions/errors'
import {
  CALL_CREDIT_POST,
  CALL_CREDIT_DETAILS_POST,
  CALL_CREDIT_DETAILS_EXTENDED_POST,
  CALL_CREDIT_MOVE_PUT,
  CALL_CREDIT_RESERVE_PUT,
  CALL_CREDIT_SPEND_PUT,
  CALL_CREDIT_UNASSIGNED_POST,
  CALL_CREDIT_UNASSIGNED_DETAILS_POST,
  CALL_CREDIT_TENANT_GET,
  setCreditDetails,
  setCreditDetailsExt,
  setCreditUnassigned,
  setCreditUnassignedExt,
  setCreditTenant,
  setCreditLoading,
  CALL_CREDIT_AVAILABLE_GET,
  setCreditAvailable,
  setCreditAvailableInfoData,
  CALL_CREDIT_REPORT_POST
} from '../actions/credits'
import {
  postApiCredit,
  postApiCreditDetails,
  postApiCreditDetailsExtended,
  putApiCreditMove,
  putApiCreditReserve,
  putApiCreditSpend,
  postApiCreditUnassigned,
  postApiCreditUnassignedDetails,
  getApiCredit
} from '../apis/credits'
import { postApiReport } from '../apis/reports'
import { notify } from '../helpers/saga'

var moment = require('moment')
require('moment-duration-format')

export default function* watchCredits() {
  yield takeEvery(CALL_CREDIT_POST, watchCreditPost)
  yield takeEvery(CALL_CREDIT_DETAILS_POST, watchCreditDetailsPost)
  yield takeEvery(
    CALL_CREDIT_DETAILS_EXTENDED_POST,
    watchCreditDetailsExtendedPost
  )
  yield takeEvery(CALL_CREDIT_MOVE_PUT, watchCreditMovePut)
  yield takeEvery(CALL_CREDIT_REPORT_POST, watchCreditReportPost)
  yield takeEvery(CALL_CREDIT_RESERVE_PUT, watchCreditReservePut)
  yield takeEvery(CALL_CREDIT_SPEND_PUT, watchCreditSpendPut)
  yield takeEvery(CALL_CREDIT_UNASSIGNED_POST, watchCreditUnassignedPost)
  yield takeEvery(
    CALL_CREDIT_UNASSIGNED_DETAILS_POST,
    watchCreditUnassignedDetailsPost
  )
  yield takeEvery(CALL_CREDIT_TENANT_GET, watchCreditTenantGet)
  yield takeEvery(CALL_CREDIT_AVAILABLE_GET, watchCreditAvailableGet)
}

export function* watchCreditPost(action) {
  try {
    yield put(setCreditLoading(true))
    yield call(postApiCredit, action.data)
  } catch (error) {
    yield put(addError(CALL_CREDIT_POST, error))
  } finally {
    yield put(setCreditLoading(false))
  }
}

export function* watchCreditDetailsPost(action) {
  try {
    yield put(setCreditLoading(true))
    const data = yield call(postApiCreditDetails, action.data)
    yield put(setCreditDetails(data))
  } catch (error) {
    yield put(addError(CALL_CREDIT_DETAILS_POST, error))
  } finally {
    yield put(setCreditLoading(false))
  }
}

const calcExecTime = item => {
  const startD = moment(item.startedAt)
  const endD = moment(item.finishedAt)
  const duration = moment.duration(endD.diff(startD))
  item.executionTime = duration.format('d [days] h[h] m[m] s[s]')
}

export function* watchCreditDetailsExtendedPost(action) {
  try {
    yield put(setCreditLoading(true))
    const data = yield call(postApiCreditDetailsExtended, action.data)
    const endD = moment()
    data.data.forEach(item => {
      let state = 0
      const startD = moment(item.expiresAt).add('days', 1)
      const duration = moment.duration(startD.diff(endD))
      if (duration.asDays() < 30) state = 1
      if (duration.asDays() < 10) state = 2
      const durationFormat =
        duration.asDays() <= 1 ? 'h [hours]' : 'M [months] d [days]'
      const formattedDuration = duration.format(durationFormat)
      item.validity = [formattedDuration, state]
      calcExecTime(item)
    })
    yield put(setCreditDetailsExt(data))
  } catch (error) {
    yield put(addError(CALL_CREDIT_DETAILS_EXTENDED_POST, error))
  } finally {
    yield put(setCreditLoading(false))
  }
}

export function* watchCreditReportPost(action) {
  try {
    yield call(postApiReport, action.tenantId, action.reportType)
    yield fork(notify, 'Report generation started successfully.')
  } catch (error) {
    yield put(
      addError(
        CALL_CREDIT_REPORT_POST,
        'Something went wrong while starting report generation'
      )
    )
  }
}

export function* watchCreditMovePut(action) {
  try {
    yield put(setCreditLoading(true))
    yield call(putApiCreditMove, action.data)
  } catch (error) {
    yield put(addError(CALL_CREDIT_MOVE_PUT, error))
  } finally {
    yield put(setCreditLoading(false))
  }
}

export function* watchCreditReservePut(action) {
  try {
    yield put(setCreditLoading(true))
    yield call(putApiCreditReserve, action.data)
  } catch (error) {
    yield put(addError(CALL_CREDIT_RESERVE_PUT, error))
  } finally {
    yield put(setCreditLoading(false))
  }
}

export function* watchCreditSpendPut(action) {
  try {
    yield put(setCreditLoading(true))
    yield call(putApiCreditSpend, action.data)
  } catch (error) {
    yield put(addError(CALL_CREDIT_SPEND_PUT, error))
  } finally {
    yield put(setCreditLoading(false))
  }
}

export function* watchCreditUnassignedPost(action) {
  try {
    yield put(setCreditLoading(true))
    const data = yield call(postApiCreditUnassigned, action.data, action.filter)
    yield put(setCreditUnassigned(data))
  } catch (error) {
    yield put(addError(CALL_CREDIT_UNASSIGNED_POST, error))
  } finally {
    yield put(setCreditLoading(false))
  }
}

export function* watchCreditUnassignedDetailsPost(action) {
  try {
    yield put(setCreditLoading(true))
    const data = yield call(postApiCreditUnassignedDetails, action.data)

    const endD = moment()
    data.data?.forEach(item => {
      let state = 0

      const startD = moment(item.expiresAt).add('days', 1)
      const duration = moment.duration(startD.diff(endD))
      if (duration.asDays() < 30) state = 1
      if (duration.asDays() < 10) state = 2
      const durationFormat =
        duration.asDays() <= 1 ? 'h [hours]' : 'M [months] d [days]'
      const formattedDuration = duration.format(durationFormat)
      item.validity = [formattedDuration, state]
    })
    yield put(setCreditUnassignedExt(data))
  } catch (error) {
    yield put(addError(CALL_CREDIT_UNASSIGNED_DETAILS_POST, error))
  } finally {
    yield put(setCreditLoading(false))
  }
}

export function* watchCreditTenantGet(action) {
  try {
    yield put(setCreditLoading(true))
    const data = yield call(getApiCredit, action.tenantId, action.filter)
    yield put(setCreditTenant(data))
  } catch (error) {
    yield put(addError(CALL_CREDIT_TENANT_GET, error))
  } finally {
    yield put(setCreditLoading(false))
  }
}

export function* watchCreditAvailableGet(action) {
  try {
    yield put(setCreditLoading(true))
    if (action.tenantId) {
      const data2 = {
        statusFilter: ['Available'], //, 'Spent', 'Reserved', 'Repaid'
        offset: 0,
        limit: 25,
        tenantId: action.tenantId
      }
      const data3 = yield call(postApiCreditDetailsExtended, data2)
      yield put(setCreditAvailableInfoData(data3))
    }

    const data = yield call(getApiCredit, action.tenantId, ['Available'])
    const availableCredits = data[0]?.credits ?? 0
    yield put(setCreditAvailable(availableCredits))
  } catch (error) {
    yield put(addError(CALL_CREDIT_AVAILABLE_GET, error))
  } finally {
    yield put(setCreditLoading(false))
  }
}
