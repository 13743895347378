import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { compose } from 'recompose'
import { injectIntl, FormattedMessage } from 'react-intl'
import merge from 'lodash/fp/merge'
import EditMetadata from './EditMetadata'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import MikeButton from '@mike/mike-shared-frontend/mike-button'
import messages from '../../shared/messages'
import TextField from '../formik/TextField'

const defaultInitialValues = {
    name: '',
    description: '',
    metadata: []
}

/* Did not get it to work...
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string(),
  metadata: Yup.array()
  .of(
    Yup.object({
      key: Yup.string().required('Required').matches(/^[A-Za-z0-9_]+$/g, "Only latin characters"),
      value: Yup.string().required('Required')
    })
  )  
}) */

const validateForm = values => {
    const errors = {}
    const REQUIRED = 'Required'
    const INVALIDKEY = 'Invalid key'
    const UNIQUEKEY = 'Key not unique'

    if (!values.name) {
        errors.name = REQUIRED
    }
    if (values.metadata && values.metadata.length > 0) {
        const metadata = []
        const keys = values.metadata.map(md => md.key)

        values.metadata.forEach(md => {
            const sameKeys = keys.filter(existingKey => existingKey === md.key)
            const keyMessage =
                md.key.length === 0
                    ? REQUIRED
                    : !/^[A-Za-z0-9_]+$/g.test(md.key)
                        ? INVALIDKEY
                        : sameKeys.length > 1
                            ? UNIQUEKEY
                            : ''
            const errorMessage = {
                key: keyMessage,
                value: md.value.length > 0 ? '' : REQUIRED
            }
            metadata.push(errorMessage)
        })
        const check = metadata.filter(
            md =>
                md.value === REQUIRED || md.key === INVALIDKEY || md.key === UNIQUEKEY
        )
        if (check.length > 0) {
            errors.metadata = metadata
        }
    }

    return errors
}

const styles = theme => ({
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1)
    },
    submitButton: {
        width: theme.spacing(39) // width of shared mike-sliding-panel (360px) - left and right padding (24px each)
    }
})

const EditDatasetForm = ({ classes, initialValues, intl, onSubmit }) => {
    const mergedInitialValues = merge(defaultInitialValues, initialValues)

    const values = {
        name: mergedInitialValues.name,
        description: mergedInitialValues.description,
        metadata: Object.entries(mergedInitialValues.metadata).map(
            ([key, value]) => ({
                key,
                value: typeof value === 'object' ? JSON.stringify(value) : value
            })
        )
    }

    const onUpdate = values => {
        const metas = values.metadata.map(({ key, value }) => [key, value])
        const md = Object.fromEntries(metas)
        const updatedValues = {
            ...mergedInitialValues,
            name: values.name,
            description: values.description,
            metadata: md
        }
        onSubmit(updatedValues)
    }

    const [editingMetadata, setEditingMetdata] = useState(false)

    const handleSetEditMetadata = () => {
        setEditingMetdata(true)
    }

    return (
        <Formik
            initialValues={values}
            validate={validateForm}
            onSubmit={onUpdate}
            render={formikProps => {
                const { setFieldValue } = formikProps
                const handleChangeMetadata = metaDatas => {
                    setFieldValue('metadata', metaDatas)
                }

                return (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="name"
                                    label={intl.formatMessage(messages.name)}
                                    fullWidth
                                    required
                                    autoFocus
                                    addPlaceHolder={true}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    name="description"
                                    label={intl.formatMessage(messages.description)}
                                    fullWidth
                                    type="textarea"
                                    rows={4}
                                    addPlaceHolder={true}
                                />
                            </Grid>

                            <EditMetadata
                                name="metadata"
                                values={values.metadata}
                                onChangeMetadata={handleChangeMetadata}
                                editing={editingMetadata}
                                setEditing={handleSetEditMetadata}
                            />

                            <Grid item xs={12}>
                                <MikeButton
                                    className={classes.submitButton}
                                    color="secondary"
                                    // variant="contained"
                                    type="submit"
                                >
                                    <FormattedMessage
                                        id={
                                            mergedInitialValues.datasetType === 'file'
                                                ? 'editDatasetForm.button.updateFile'
                                                : 'editDatasetForm.button.updateDataset'
                                        }
                                    />
                                </MikeButton>
                            </Grid>
                        </Grid>
                    </Form>
                )
            }}
        />
    )
}
EditDatasetForm.propTypes = {
    classes: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired
}

const enhance = compose(injectIntl, withStyles(styles))

export default enhance(EditDatasetForm)
