import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'formik'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import AppPropTypes from '../../shared/appPropTypes'
import { getNameInitials } from '../../helpers/common'
import { ROLE_TYPES } from '../../helpers/project'
import Tooltip from '@material-ui/core/Tooltip'

import withFormik from './withFormik'
import SelectField from './SelectField'

const ProjectMembersField = ({ name, loading, value, disabled }) => {
  const members = uniqBy(value, 'userId')

  return loading ? (
    <div style={{ textAlign: 'center', padding: '1em' }}>
      <CircularProgress />
    </div>
  ) : (
    <FieldArray name={name}>
      {arrayHelpers => (
        <Grid container direction="column" justifyContent="flex-start">
          {map(members, (member, index) => (
            <MemberListItem
              key={member.userId}
              name={name}
              disabled={disabled}
              index={index}
              member={member}
              onDelete={() => arrayHelpers.remove(index)}
            />
          ))}
        </Grid>
      )}
    </FieldArray>
  )
}
ProjectMembersField.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  value: PropTypes.arrayOf(AppPropTypes.projectMember)
}

// TODO: missing reader color like in ProjectMembers
const colorByRole = role =>
  role === ROLE_TYPES.OWNER
    ? 'primary'
    : role === ROLE_TYPES.CONTRIBUTOR
    ? 'secondary'
    : undefined

const MemberListItem = ({ name, index, member, disabled, onDelete }) => {
  const { name: displayName, role } = member
  const initials = getNameInitials(displayName)
  const roleFieldName = `${name}[${index}].role`

  if (member.type === 'OpenAPIAccount') {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ maxHeight: 60 }}
      >
        <Grid item>
          <Chip
            style={{ maxWidth: 150 }}
            color={colorByRole(role)}
            avatar={<Avatar>{initials}</Avatar>}
            label={displayName ? displayName : 'Deleted User'}
            onDelete={disabled ? null : onDelete}
          />
        </Grid>
        <Grid item>
          <SelectField
            name={roleFieldName}
            value={role}
            disabled={disabled}
            valueField="name"
            primaryField="name"
            items={map(Object.values(ROLE_TYPES), role => ({
              name: role
            }))}
            required={!disabled}
            fullWidth
          />
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Tooltip title={'Email: ' + member.email} placement="bottom-start">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ maxHeight: 60 }}
        >
          <Grid item>
            <Chip
              style={{ maxWidth: 150 }}
              color={colorByRole(role)}
              avatar={<Avatar>{initials}</Avatar>}
              label={displayName ? displayName : 'Deleted User'}
              onDelete={disabled ? null : onDelete}
            />
          </Grid>
          <Grid item>
            <SelectField
              name={roleFieldName}
              value={role}
              disabled={disabled}
              valueField="name"
              primaryField="name"
              items={map(Object.values(ROLE_TYPES), role => ({
                name: role
              }))}
              required={!disabled}
              fullWidth
            />
          </Grid>
        </Grid>
      </Tooltip>
    )
  }
}
MemberListItem.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  member: AppPropTypes.projectMember,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default withFormik(ProjectMembersField)
