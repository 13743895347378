import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'

const BASE_COLOR = '#00A4EC'

const DownloadReportIcon = ({ colorR = BASE_COLOR, ...props }) => (
  <SvgIcon {...props} viewBox="-3 -2 20 20">
    <path
      fill={colorR ? colorR : BASE_COLOR}
      d="M14 6H10V0H4V6H0L7 13L14 6ZM6 8V2H8V8H9.17L7 10.17L4.83 8H6ZM0 15H14V17H0V15Z"
    />
  </SvgIcon>
)
DownloadReportIcon.propTypes = {
  colorR: PropTypes.string
}
export default DownloadReportIcon
