import { metadata } from '../support'
import { ReportTypeEnum } from './enums'
import { getReportRequestBody } from './utils'

const BASE_API_URL = 'admin/credit/report'
const VERSION = 4

export const postApiReport = async (
  tenantId: string,
  reportType: ReportTypeEnum
) => {
  const body = getReportRequestBody(tenantId, reportType)

  try {
    return await metadata.postJson(BASE_API_URL, body, VERSION)
  } catch (_error) {
    return []
  }
}
