import { all } from 'redux-saga/effects'

import watchCustomerUsers from './customerUsers'

import watchDatasetReaders from './datasetReaders'
import watchErrors from './errors'
import watchExportAndDownload from './exportAndDownload'
import watchExports from './exports'
import watchImports from './imports'
import watchIntl from './intl'
import watchProject from './project'
import watchProjects from './projects'
import watchRecycleBin from './recycleBin'
import watchUi from './ui'
import watchUploadAndImport, {
  watchUploadAndImportMany
} from './uploadAndImport'
import watchUploads from './uploads'
import watchFetchApps from './applications'
import watchAuth from './auth'
import watchProgress from './progressItems'
import watchConverts from './converts'
import watchDatasetWriters from './datasetWriters'
import watchFetchCoordinateSystems from './coordinateSystems'
import watchAdmin from './admin'
import watchUsers from './users'
import watchApiAccess from './apiAccess'
import watchGroup from './groups'
import watchFeatures from './features'
import watchSiteCpuUsage from './siteCpuUsage'
import watchSiteStorageUsage from './siteStorageUsage'
import watchProjectNames from './projectNames'
import watchRootProjectList from './rootProjectList'
import watchCredits from './credits'

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchAdmin(),
    watchCredits(),
    watchConverts(),
    watchCustomerUsers(),
    watchDatasetReaders(),
    watchErrors(),
    watchExportAndDownload(),
    watchExports(),
    watchImports(),
    watchIntl(),
    watchProject(),
    watchProjects(),
    watchRecycleBin(),
    watchUi(),
    watchUploadAndImport(),
    watchUploadAndImportMany(),
    watchUploads(),
    watchProgress(),
    watchDatasetWriters(),
    watchFetchApps(),
    watchFetchCoordinateSystems(),
    watchUsers(),
    watchApiAccess(),
    watchGroup(),
    watchFeatures(),
    watchSiteCpuUsage(),
    watchSiteStorageUsage(),
    watchProjectNames(),
    watchRootProjectList()
  ])
}
