import React, { useEffect, useState } from 'react'
import { css } from 'emotion'
import Typography from '@material-ui/core/Typography'
import theme from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { ReactComponent as Edit } from '@mike/mike-shared-frontend/media/icons/Edit'
import Button from '@material-ui/core/Button'
import SiteInfoNav from '../SiteInfoNav'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useTypedSelector } from '../../reducers'
import { getProject } from '../../apis/admin'
import { loadAdminCredits } from '../../actions/admin'
import { mikePalette } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { MikeSlidingPanel } from '@mike/mike-shared-frontend'
import SiteDetailsForm from '../SiteDetailsForm'
import { updateTenant } from '../../actions/tenant'
import { setBillingInformation } from '../../actions/billing'

const paddingStyle = css`
  padding: ${theme.spacing(1)}px;
`
const useStyles = makeStyles(theme => ({
    tableTopHeader: {
        position: 'sticky',
        top: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        zIndex: 1,
        backgroundColor: '#f2f5f7'
    },
    detailsHeader: {
        display: 'flex'
    },
    detailTable: {
        display: 'grid',
        paddingLeft: theme.spacing(1),
        gridTemplateColumns: 'auto minmax(0, 1fr)',
        gridGap: `0 ${theme.spacing(1)}px`
    }
}))

const iconStyle = css`
  path {
    fill: ${mikePalette.secondary.main};
  }
`

const SiteDetails = props => {
    const classes = useStyles(props)
    const intl = useIntl()
    const tenantId = useTypedSelector(state => state['auth'].user?.tenantId) || ''
    const tenantName = useTypedSelector((state) => {
        const foundTenant = state.tenant.tenants.find((tenant) => tenant.id === state.auth.user?.tenantId);
        return foundTenant?.name || ''
    })
    const credits = useTypedSelector(state => state['admin'].credits)
    const billingInformation = useTypedSelector(state => state['billing'])

    // const [numberOfUsers, setNumberOfUsers] = useState(0)
    // const [numberOfAdmins, setNumberOfAdmins] = useState(0)
    const [showEditor, setShowEditor] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadAdminCredits())
        if (tenantId) {
            getProject(tenantId).then(res => {
                dispatch(setBillingInformation(res?.billingInformation))
                // if (res?.members) {
                //   setNumberOfUsers(res.members.length)
                //   setNumberOfAdmins(res.members.filter(m => m.role === 'Owner').length)
                // }
            })
        }
    }, [dispatch, tenantId])

    return (
        <>
            <SiteInfoNav />

            <div className={classes.tableTopHeader}>
                <div className={classes.detailsHeader}>
                    <Typography className={paddingStyle} variant="h4" color="textPrimary">
                        {intl.formatMessage({ id: 'screens.siteInfo.siteDetails' })}
                    </Typography>

                    <Button
                        size="small"
                        aria-label="Edit"
                        onClick={() => setShowEditor(true)}
                    >
                        <Edit
                            width="30"
                            height="30"
                            viewBox="0 0 40 40"
                            className={iconStyle}
                        />
                    </Button>
                </div>
                <div className={classes.detailTable}>
                    <div>
                        <strong>
                            {intl.formatMessage({ id: 'screens.siteInfo.siteName' })}
                        </strong>
                    </div>
                    <div>{tenantName}</div>
                    <div>
                        <strong>
                            {intl.formatMessage({ id: 'screens.siteInfo.billingReference' })}
                        </strong>
                    </div>
                    <div>{billingInformation.billingReference}</div>
                    <div>
                        <strong>
                            {intl.formatMessage({ id: 'screens.siteInfo.remainingCredits' })}
                        </strong>
                    </div>
                    <div>{credits}</div>
                </div>
                {/*<Typography className={paddingStyle} variant="h4" color="textPrimary">*/}
                {/*  {intl.formatMessage({ id: 'screens.siteInfo.aboutYourUsers' })}*/}
                {/*</Typography>*/}
                {/*<div className={classes.detailTable}>*/}
                {/*  <div>*/}
                {/*    <strong>*/}
                {/*      {intl.formatMessage({ id: 'screens.siteInfo.numberOfUsers' })}*/}
                {/*    </strong>*/}
                {/*  </div>*/}
                {/*  <div>{numberOfUsers}</div>*/}
                {/*  <div>*/}
                {/*    <strong>*/}
                {/*      {intl.formatMessage({*/}
                {/*        id: 'screens.siteInfo.usersWithOwnerRights'*/}
                {/*      })}*/}
                {/*    </strong>*/}
                {/*  </div>*/}
                {/*  <div>{numberOfAdmins}</div>*/}
                {/*</div>*/}
            </div>

            <MikeSlidingPanel
                position="right"
                isOpen={showEditor}
                onClose={() => {
                    setShowEditor(false)
                }}
                titleArea={intl.formatMessage({
                    id: 'screens.siteDetailsForm.headline'
                })}
                contentArea={
                    <SiteDetailsForm
                        values={{
                            tenantName,
                            billingReference: billingInformation?.billingReference || ''
                        }}
                        handleSubmit={value => {
                            const newBillingInformation = {
                                ...billingInformation,
                                billingReference: value.billingReference
                            }
                            dispatch(
                                updateTenant(
                                    {
                                        id: tenantId,
                                        name: value.tenantName
                                    },
                                    newBillingInformation
                                )
                            )
                            setBillingInformation(newBillingInformation)
                            setShowEditor(false)
                        }}
                    />
                }
                actionsArea={null}
                noGrayOverlay={false}
            />
        </>
    )
}

export default SiteDetails
